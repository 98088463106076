var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('mf-loading-dialog',{attrs:{"loading":_vm.loading}},[_c('v-container',{staticClass:"pb-2 mt-n4",attrs:{"fluid":""}},[_c('v-layout',[_c('p',{staticClass:"ml-1 page-subtitle"},[_vm._v("Cadastrar Retorno Abono Temporário")]),_c('v-spacer')],1),_c('v-card-actions',{staticClass:"px-0 mt-2 mb-1"},[_c('v-btn',{attrs:{"outlined":"","width":"300","color":"error"},on:{"click":function($event){return _vm.$router.push(("/retailers/" + _vm.clientId + "/edit/acoes"))}}},[_vm._v("Cancelar")]),_c('v-spacer')],1),_c('v-card',{staticClass:"pt-4"},[_c('v-card-title',{staticClass:"mb-6"},[_c('v-text-field',{staticClass:"pt-0 mt-0",attrs:{"width":"300","hide-details":"","single-line":"","placeholder":"Busca (nome do produto)","append-icon":"search","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-spacer'),_c('v-btn',{staticClass:"mr-5",attrs:{"color":"error","outlined":"","width":"160","disabled":!_vm.selected.length},on:{"click":function($event){_vm.selected = []}}},[_c('v-icon',{staticClass:"mr-1"},[_vm._v("mdi-trash-can-outline")]),_vm._v(" Limpar")],1),_c('v-btn',{attrs:{"color":"primary","disabled":!_vm.selected.length},on:{"click":function($event){_vm.confirm_sale = true}}},[_c('v-icon',{staticClass:"mr-2 mb-1"},[_vm._v("mdi-content-save-check")]),_vm._v(" Concluír retorno do abono temporário")],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"loading":_vm.loading,"footer-props":{ itemsPerPageOptions: [5, 10, 25, 50] },"server-items-length":_vm.maxRecords,"options":_vm.options,"headers":_vm.headers,"items":_vm.activeProductsData,"search":_vm.search,"item-key":"_id","show-select":""},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.product_type",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.getProductFromType(item.product_type).name)+" ")])],1)]}},{key:"item.temporary_churn",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":item.temporary_churn ? 'error' : 'success'}},[_vm._v(_vm._s(item.temporary_churn ? 'mdi-alert' : 'mdi-check-circle'))]),_vm._v(_vm._s(item.temporary_churn ? 'Sim' : 'Não')+" ")],1)],1)]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":"","color":item.active ? 'success' : 'error'}},[_vm._v("mdi-brightness-1")]),_vm._v(_vm._s(item.active ? 'Ativo' : 'Inativo')+" ")],1)],1)]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.formatDate(item.created_at))+" ")])],1)]}},{key:"item.total_mrr",fn:function(ref){
var item = ref.item;
return [_c('v-flex',[_c('v-layout',{attrs:{"justify-center":"","align-center":""}},[_vm._v(" "+_vm._s(_vm.parseCurrencyDefault(item.total_mrr))+" ")])],1)]}}],null,true),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1),_c('dialog-confirm-sale',{attrs:{"validate-contract-link":false,"hide-date-input":false,"disable-button":_vm.disabled},on:{"confirm-action":_vm.setReturnTemporaryChurn},model:{value:(_vm.confirm_sale),callback:function ($$v) {_vm.confirm_sale=$$v},expression:"confirm_sale"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }