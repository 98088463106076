<template>
  <mf-loading-dialog :loading="loading">
    <v-container class="pb-2 mt-n4" fluid>
      <v-layout>
        <p class="ml-1 page-subtitle">Cadastrar Retorno Abono Temporário</p>
        <v-spacer />
      </v-layout>
      <v-card-actions class="px-0 mt-2 mb-1">
        <v-btn outlined width="300" color="error" @click="$router.push(`/retailers/${clientId}/edit/acoes`)">Cancelar</v-btn>
        <v-spacer />
      </v-card-actions>
      <v-card class="pt-4">
        <v-card-title class="mb-6">
          <v-text-field
            v-model="search"
            class="pt-0 mt-0"
            width="300"
            hide-details
            single-line=""
            placeholder="Busca (nome do produto)"
            append-icon="search"
            outlined
          />
          <v-spacer />
          <v-btn color="error" outlined class="mr-5" width="160" :disabled="!selected.length" @click="selected = []"
            ><v-icon class="mr-1">mdi-trash-can-outline</v-icon> Limpar</v-btn
          >
          <v-btn color="primary" :disabled="!selected.length" @click="confirm_sale = true"
            ><v-icon class="mr-2 mb-1">mdi-content-save-check</v-icon> Concluír retorno do abono temporário</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-data-table
            v-model="selected"
            :loading="loading"
            :footer-props="{ itemsPerPageOptions: [5, 10, 25, 50] }"
            :server-items-length="maxRecords"
            :options.sync="options"
            :headers="headers"
            :items="activeProductsData"
            :search="search"
            item-key="_id"
            show-select
          >
            <template v-slot:[`item.product_type`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  {{ getProductFromType(item.product_type).name }}
                </v-layout>
              </v-flex>
            </template>

            <template v-slot:[`item.temporary_churn`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  <v-icon class="mr-2" small :color="item.temporary_churn ? 'error' : 'success'">{{
                    item.temporary_churn ? 'mdi-alert' : 'mdi-check-circle'
                  }}</v-icon
                  >{{ item.temporary_churn ? 'Sim' : 'Não' }}
                </v-layout>
              </v-flex>
            </template>

            <template v-slot:[`item.active`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  <v-icon class="mr-2" x-small :color="item.active ? 'success' : 'error'">mdi-brightness-1</v-icon>{{ item.active ? 'Ativo' : 'Inativo' }}
                </v-layout>
              </v-flex>
            </template>

            <template v-slot:[`item.created_at`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  {{ formatDate(item.created_at) }}
                </v-layout>
              </v-flex>
            </template>

            <template v-slot:[`item.total_mrr`]="{ item }">
              <v-flex>
                <v-layout justify-center align-center>
                  {{ parseCurrencyDefault(item.total_mrr) }}
                </v-layout>
              </v-flex>
            </template>
          </v-data-table>
        </v-card-text>
        <dialog-confirm-sale
          v-model="confirm_sale"
          :validate-contract-link="false"
          :hide-date-input="false"
          :disable-button="disabled"
          @confirm-action="setReturnTemporaryChurn"
        />
      </v-card>
    </v-container>
  </mf-loading-dialog>
</template>

<script>
import { QUERY_GET_AVAILABLE_PRODUCTS, MUTATION_INSERT_SALE } from '@/modules/retailers/graphql'
import { parseCurrency, productsInformation } from '@/mixin'
export default {
  components: {
    DialogConfirmSale: () => import('../../../../../components/atomic-components/molecules/DialogConfirmSale')
  },
  mixins: [parseCurrency, productsInformation],
  props: {
    clientId: {
      type: String,
      default: () => ''
    },
    salesType: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    loading: false,
    search: '',
    options: {},
    maxRecords: 0,
    activeProductsData: [],
    selected: [],
    disabled: false,
    confirm_sale: false
  }),
  computed: {
    isCreate() {
      return this.$route.meta.title === 'Retorno Abono temporário'
    },
    headers() {
      return [
        {
          text: 'ID Produto',
          value: '_id',
          align: 'center'
        },
        {
          text: 'Produto',
          value: 'product_type',
          align: 'center'
        },
        {
          text: 'Status',
          value: 'active',
          align: 'center'
        },
        {
          text: 'Abono temporário',
          value: 'temporary_churn',
          align: 'center'
        },
        {
          text: 'Total MRR',
          value: 'total_mrr',
          align: 'center'
        },
        {
          text: 'Total disponível',
          value: 'slots',
          align: 'center'
        },
        {
          text: 'Criado em',
          value: 'created_at',
          align: 'center'
        }
      ]
    }
  },
  apollo: {
    availableProducts: {
      query: QUERY_GET_AVAILABLE_PRODUCTS,
      fetchPolicy: 'network-only',
      variables() {
        return Object.assign(
          {},
          { client_id: this.$route.params.id },
          { filters: { temporary_churn: true } },
          {
            pagination: {
              page: this.options.page || 1,
              pageSize: this.options.itemsPerPage || 10,
              search: this.search || null,
              sort: this.options.sortBy || ['_id'],
              sortDirection: this.options.sortDesc && this.options.sortDesc.map(direction => (direction ? 'DESC' : 'ASC'))
            }
          }
        )
      },
      update({ availableProducts: { count, data } }) {
        this.maxRecords = count
        this.activeProductsData = data
      },
      context() {
        return {
          headers: {
            authorization: localStorage.getItem('session_id')
          },
          uri: this.$microservicesUrls['crm']
        }
      }
    }
  },
  methods: {
    formatDate(date) {
      return this.$moment(date)
        .add(3, 'h')
        .format('DD/MM/YYYY - HH:mm:ss')
    },
    async setReturnTemporaryChurn(item) {
      this.disabled = true
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_INSERT_SALE,
          variables: {
            client_id: this.clientId,
            sale: {
              type: 'revert_temporary_churn',
              contract_link: item.contract_link,
              created_at: item.date,
              items: this.selected.map(item => {
                return {
                  sale_id: item.sale_id,
                  index: item.index,
                  name: item.name,
                  type: item.product_type,
                  quantity: item.temporary_churn.aboned_quantity,
                  unit_value: item.temporary_churn.aboned_value,
                  total_value: item.temporary_churn.aboned_quantity * item.temporary_churn.aboned_value
                }
              }),
              observation: item.observation
            }
          },
          context: {
            uri: this.$microservicesUrls['crm']
          }
        })

        this.$snackbar({ message: 'Retorno de Abono temporário cadastrado com sucesso', snackbarColor: 'success' })
        this.$router.push(`/retailers/${this.clientId}/edit/acoes`)
        this.disabled = false
      } catch (err) {
        console.log(err?.message)
        this.$snackbar({ message: 'Falha ao registrar retorno de abono temporário', snackbarColor: 'error' })
        this.disabled = false
      }
    }
  }
}
</script>
